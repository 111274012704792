.wrapperClose {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: fixed;
  right: 9px;
  bottom: 35px;
  cursor: pointer;
  z-index: 99999999999;
  background-color: rgb(44, 64, 90);
  color: white;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(128, 128, 128, 0.41);
  padding: 10px;

  .btn {
    border: none;
    background: none;
    outline: none;
  }
}

.content {
  padding: 10px;
  height: 100%;

  .msgBot,
  .msgUser {
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    clear: both;
    animation: animationMsg 0.5s;
    hyphens: auto;
    white-space: pre-wrap;
  }

  .msgBot {
    color: rgb(44, 64, 90);
    background-color: rgba(234, 231, 231, 0.64);
    float: left;
  }

  .msgUser {
    color: white;
    background-color: rgb(44, 64, 90);
    float: right;
  }

  .svgIcon {
    margin-right: 10px;
  }
}

.wrapperOpen {
  background-color: white;
  border-radius: 10px;
  border: 1px solid rgba(128, 128, 128, 0.41);
}

.suggestedQuestions {
  margin-top: 10px;
  width: 100%;
  z-index: 2121212121;
  position: relative;

  .msgButtonBot {
    margin-left: 10px;
    margin-right: 10px;
    padding: 5px;
    color: rgb(44, 64, 90);
    background-color: rgba(234, 231, 231, 0.64);
    border-radius: 10px;
    margin-bottom: 10px;
    float: left;
    clear: both;
    border: 1px solid rgba(255, 255, 255, 0);
    animation: animationMsg 0.5s;
    hyphens: auto;
    cursor: pointer;
    text-align: start;
    outline: none;
    font-size: 0.7em;

    &:hover {
      background-color: rgba(234, 231, 231, 0.44);
      color: rgb(31, 46, 65);
      border: 1px solid rgb(31, 46, 65);
    }
  }
}

.msgButtonBot:disabled {
  background-color: #cccccc;
  color: #666666;
  border: 1px solid #cccccc;
  cursor: default;

  &:hover {
    background-color: #cccccc;
    color: #666666;
    border: 1px solid #cccccc;
    cursor: default
  }
}

.skeleton {
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 5px;
  margin-bottom: 10px;
  height: 28.8px;
  position: relative;
  background-color: rgba(234, 231, 231, 0.44);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 80%;
    background: linear-gradient(to right, transparent 0%, rgba(234, 231, 231, 1) 50%, transparent 100%);
    animation: load 1.2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}

@keyframes load {
  from {
    left: -150px;
  }

  to {
    left: 100%;
  }
}

.wrapperInput {
  position: relative;

  .pushMsg {
    position: absolute;
    right: 10px;
    bottom: 12px;
    border: none;
    background: none;
    outline: none;
    z-index: 99999999999;
  }

  .textMsg {
    position: relative;
    width: 100%;
    height: 50px;
    border: none;
    border-top: 1px solid rgba(128, 128, 128, 0.41);
    outline: none;
    border-radius: 0 0 10px 10px;
    padding: 10px 40px 10px 10px;
    z-index: 99999999999;

    &:disabled {
      background-color: rgb(234, 231, 231);
      opacity: 1;
    }

    &::placeholder {
      color: rgb(44, 64, 90);
    }
  }
}

@keyframes animationMsg {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
